import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../common/button/button';
import './setup.scss';
import { updateClasses, classesSubject, stages, updateCurrentClass, updateAvailableStages, currentClassSubject } from '../../store/store';

function Setup({ setActive = () => { } }) {
  const inActive = -1;
  const [activeClass, setActiveClass] = useState(inActive);
  const [usingClass, setUsingClass] = useState(inActive);
  const [editClassName, setEditClassName] = useState('');
  const [classes, setClasses] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // here we are setting all classes and provide available pages
    const subscription = classesSubject.subscribe((newState) => {
      setClasses(newState);
      if (newState.length && newState.every((classItem) => classItem.imagesAmount)) {
        updateAvailableStages([stages.SETUP, stages.CAPTURE, stages.TRAIN])
      } else {
        updateAvailableStages([stages.SETUP])
      }
    });

    handleActive(inActive)

    updateCurrentClass({});

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleNext = (path, stage) => {
    setActive(path, stage);
    navigate(path);
  };

  const handleActive = (idx, classItem) => {
    const classNewItem = classesSubject.value.find((item) => item.id === classItem?.id)
    if ((idx !== inActive) && classNewItem) {
      setActiveClass(idx);
      updateCurrentClass(classNewItem);
    }
  };

  const openEdit = (idx) => {
    setIsEdit(true);
    setUsingClass(idx);
    setEditClassName(classes[idx].name);
  };

  const openDelete = (idx) => {
    setIsDelete(true);
    setUsingClass(idx);
  };

  const closeChange = () => {
    setUsingClass(inActive);
    setIsEdit(false);
    setIsDelete(false);
    setEditClassName('');
  };

  const createClass = () => {
    updateClasses([...classes, { id: classes.length ? classes[classes.length - 1].id + 1 : 0, name: 'New class', src: '', imagesAmount: 0, photoSets: [] }]);
  };

  const deleteClass = () => {
    if (usingClass >= 0) {
      const updatedClasses = classes.filter((_, classIdx) => classIdx !== usingClass);
      updateClasses(updatedClasses);
      setClasses(updatedClasses);
      setActiveClass(inActive);
      if (!updatedClasses.length) {
        updateAvailableStages([stages.SETUP])
      }
      closeChange();
    }
  };

  const editClass = () => {
    if (editClassName.trim() !== '') {
      const updatedClasses = classes.map((classItem, idx) => {
        if (idx === usingClass) {
          const result = { ...classItem, name: editClassName }
          updateCurrentClass(result);
          return result;
        }
        return classItem
      });
      updateClasses(updatedClasses);
      setClasses(updatedClasses);
      closeChange();
    }
  };

  return (
    <div className="setup">
      <div className="main">
        <h3>Image Classes</h3>
        <div className="classes">
          {classes.map((classItem, idx) => (
            <div key={idx} className={`class-item ${activeClass === idx ? "active" : ""}`} onClick={() => handleActive(idx, classItem)}>
              <img src={classItem.src || "https://static.vecteezy.com/system/resources/thumbnails/004/141/669/small/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg"} alt="NC" />
              <div className="class-item__info">
                <div className="class-item__name-wrapper">
                  {isEdit && usingClass === idx ? (
                    <input
                      type="text"
                      value={editClassName}
                      autoFocus
                      onChange={(event) => setEditClassName(event.target.value)}
                      onBlur={editClass}
                    />
                  ) : (
                    <p className="name">{classItem.name}</p>
                  )}
                  <p className="amount-photo">{classItem.imagesAmount} images</p>
                </div>
              </div>
              <div className="class-item__buttons">
                {isEdit && usingClass === idx ? (
                  <>
                    <i className="bi bi-check-lg" onClick={editClass}></i>
                    <i className="bi bi-x-lg" onClick={closeChange}></i>
                  </>
                ) : isDelete && usingClass === idx ? (
                  <>
                    <i className="bi bi-check-lg" onClick={deleteClass}></i>
                    <i className="bi bi-x-lg" onClick={closeChange}></i>
                  </>
                ) : (
                  <>
                    <i className="bi bi-pencil" onClick={() => openEdit(idx)}></i>
                    <i className="bi bi-trash3" onClick={() => openDelete(idx)}></i>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="add-item">
          <div onClick={() => createClass()} className="add-item__action">
            <div className="plus-wrapper">
              <i className="bi bi-plus"></i>
            </div>
            <p>Add new class</p>
          </div>
          </div>
          <div className="add-item__next">
            <Button text="Next" status={activeClass === inActive ? true : false} styling="primary" handler={() => handleNext('/capture', stages.CAPTURE)}></Button>
            <p className='choose-text'>{activeClass === inActive ? '*choose class to continue' : ''}</p>

          </div>
        
      </div>
    </div>
  );
}

export default Setup;
